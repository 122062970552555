import React, { useState } from "react";
import { Button, Modal } from "rsuite";

function ConfirmDelete({
  deleteObject,
  handledeleteObject,
  setOpenDeleteModal,
  openDeleteModal,
  title,
  msg1,
  msg2,
  btn1,
  btn2
}) {
  return (
    <div>
      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <Modal.Header>
          <Modal.Title style={{ color: "red" }}>
            {" "}
           {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deleteObject && deleteObject?.child?.length > 0 ? (
            <p>
              <h6>
               {msg1}
               {/* {deleteObject?.child.map((child =>  <p key={child.id}>{child.name}</p>))} */}
              </h6>
            </p>
          ) : (
            <p>
              <h6>{msg2} {deleteObject?.name} ! </h6>
            </p>
          )}
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => handledeleteObject()}
            appearance="subtle"
            // disabled={deleteObject?.children?.length > 0 ? true : false}
          >
            {btn1}
          </Button>
          <Button onClick={() => setOpenDeleteModal(false)} appearance="primary">
            {btn2}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ConfirmDelete;
