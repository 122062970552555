import { lazy } from "react";

const DashboardNew = lazy(() => import("./pages/DashboardNew"));
const Modals = lazy(() => import("./pages/Modals"));
const BulkUpload = lazy(() => import("./pages/BulkUpload"));
const BulkUploadLeave =  lazy(() => import("./pages/BulkUploadLeave"));
const Configuration = lazy(() => import("./pages/Configuration"));
const Configurations = lazy(() => import("./pages/Configurations"));
const MasterRoll = lazy(() => import("./pages/MusterRoll"));
const MonthlyReport = lazy(() => import("./pages/MonthlyReport"));
const AddOrganisation = lazy(() => import("./components/AddOrganisation"));
const Configurations2 = lazy(() => import("./pages/Configurations2"));
const Companylist = lazy(() => import("./pages/Companylist"));
const Holidaysettings = lazy(() => import("./pages/Holidaysettings"));
const ApplicationDetails = lazy(() => import("./pages/Applicationdetails"));
const ApplyLeave = lazy(() => import("./pages/Applyleave"));
const LeaveApplication = lazy(() => import("./pages/Leaveapplication"));
const LeaveTypes = lazy(() => import("./pages/Leavetypes"));
const LeaveBalance = lazy(() => import("./pages/Leavebalance"));
const Organisation = lazy(() => import("./pages/Organisation"));
const Region = lazy(() => import("./pages/Region"));
const Addnewregion = lazy(() => import("./pages/Addnewregion"));
const Departments = lazy(() => import("./pages/Departments"));
const Addnewdepartment = lazy(() => import("./pages/Addnewdepartment"));
const Designations = lazy(() => import("./pages/Designations"));
const Addnewdesignation = lazy(() => import("./pages/Addnewdesignation"));
const Structuremapping = lazy(() => import("./pages/Structuremapping"));
const Addnewmapping = lazy(() => import("./pages/Addnewmapping"));
const Client = lazy(() => import("./pages/Client"));
const Addnewclient = lazy(() => import("./pages/Addnewclient"));
const Qrmanagement = lazy(() => import("./pages/Qrmanagement"));
const Mapnewqrcode = lazy(() => import("./pages/Mapnewqrcode"));
const Employees = lazy(() => import("./pages/Employees"));
const Addnewemployee = lazy(() => import("./pages/Addnewemployee"));
const Registration = lazy(() => import("./pages/Registration"));
const AddNewShifts = lazy(() => import("./pages/Addnewshifts"));
const AddLeaveTypes = lazy(()=> import("./pages/AddNewType"));

const routes = [
  { path: "/dashboard", exact: true, name: "Dashboard", element: DashboardNew },
  {
    path: "/Registration",
    exact: true,
    name: "Registration",
    element: Registration,
  },
  { path: "/Modals", exact: true, name: "Modals", element: Modals },
  {
    path: "/master-roll",
    exact: true,
    name: "MasterRoll",
    element: MasterRoll,
  },
  {
    path: "/holiday-settings",
    exact: true,
    name: "Holidaysettings",
    element: Holidaysettings,
  },
  {
    path: "/MonthlyReport",
    exact: true,
    name: "MonthlyReport",
    element: MonthlyReport,
  },
  {
    path: "/Company-list",
    exact: true,
    name: "Companylist",
    element: Companylist,
  },
  {
    path: "/AddOrganisation",
    exact: true,
    name: "AddOrganisation",
    element: AddOrganisation,
  },
  {
    path: "/bulkUpload",
    exact: true,
    name: "BulkUpload",
    element: BulkUpload,
  },
  {
    path: "/configurations",
    exact: true,
    name: "Configurations",
    element: Configurations,
  },
  {
    path: "/configurations2",
    exact: true,
    name: "Configurations2",
    element: Configurations2,
  },
  {
    path: "/applicationDetails",
    exact: true,
    name: "Applicationdetails",
    element: ApplicationDetails,
  },
  { path: "/applyLeave", exact: true, name: "ApplyLeave", element: ApplyLeave },
  {
    path: "/leaveApplication",
    exact: true,
    name: "LeaveApplication",
    element: LeaveApplication,
  },
  {
    path: "/configuration",
    exact: true,
    name: "Configuration",
    element: Configuration,
  },
  { path: "/leaveTypes", exact: true, name: "LeaveTypes", element: LeaveTypes },


  {
    path: "/leavebalance",
    exact: true,
    name: "LeaveBalance",
    element: LeaveBalance,
  },
  {
    path: "/bulkUploadLeave",
    exact: true,
    name: "BulkUpload",
    element: BulkUploadLeave,
  },
  {
    path: "/Organisation",
    exact: true,
    name: "Organisation",
    element: Organisation,
  },

  {
    path: "/AddNewType",
    exact: true,
    name: "AddNewType",
    element:AddLeaveTypes
  },
  { path: "/Region", exact: true, name: "Region", element: Region },
  {
    path: "/Addnewregion",
    exact: true,
    name: "Addnewregion",
    element: Addnewregion,
  },
  {
    path: "/Departments",
    exact: true,
    name: "Departments",
    element: Departments,
  },
  {
    path: "/Addnewdepartment",
    exact: true,
    name: "Addnewdepartment",
    element: Addnewdepartment,
  },
  {
    path: "/Designations",
    exact: true,
    name: "Designations",
    element: Designations,
  },
  {
    path: "/Addnewdesignation",
    exact: true,
    name: "Addnewdesignation",
    element: Addnewdesignation,
  },
  {
    path: "/Structure-mapping",
    exact: true,
    name: "Structuremapping",
    element: Structuremapping,
  },
  {
    path: "/Addnewmapping",
    exact: true,
    name: "Addnewmapping",
    element: Addnewmapping,
  },
  { path: "/Client", exact: true, name: "Client", element: Client },
  {
    path: "/Addnewclient",
    exact: true,
    name: "Addnewclient",
    element: Addnewclient,
  },
  {
    path: "/Qrmanagement",
    exact: true,
    name: "Qrmanagement",
    element: Qrmanagement,
  },
  {
    path: "/Mapnewqrcode",
    exact: true,
    name: "Mapnewqrcode",
    element: Mapnewqrcode,
  },
  { path: "/Employees", exact: true, name: "Employees", element: Employees },
  {
    path: "/Addnewemployee",
    exact: true,
    name: "Addnewemployee",
    element: Addnewemployee,
  },
  {
    path: "/AddNewShifts",
    exact: true,
    name: "AddNewShifts",
    element: AddNewShifts,
  },
];

export default routes;
