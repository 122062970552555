import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "rsuite/dist/rsuite.min.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import { lazy, Suspense, useEffect } from "react";
import ProtectedLayout from "./components/ProtectedLayout";
import HomeLayout from "./components/HomeLayout";
import { Loader } from "rsuite";
import Registration from "./pages/Registration";
import Cookies from "js-cookie";

import { Provider } from 'react-redux';
import store from './Redux/sotre';



const loading = (
  <div className="fullLoader">
    <Loader />
  </div>
);
const Login = lazy(() => import("./pages/Login"));


function App() {
  return (

    <Suspense fallback={loading}>
       <Provider store={store}>
      <Routes>
        <Route element={<HomeLayout />}>
          <Route path="/login" element={<Login />} />

        </Route>
        <Route path="*" element={<ProtectedLayout />} />
        <Route path="/Registration" element={<Registration />} />
      </Routes>
      </Provider>
    </Suspense>
  
  );
}

export default App;
