import React, { useCallback, useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { IoIosArrowBack } from "react-icons/io";
import { Button, Input, Message, toaster } from "rsuite";
import { SelectPicker, Stack } from "rsuite";
import api from "../../lib/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Cookies from "js-cookie";
// import { CustomerContext } from "./CustomerContext";
import { useSelector, useDispatch } from "react-redux";
import {
  customerInfo,
  handleOnboardingStatus,
  testData,
} from "../../Redux/userSlice";
import NotificationMessage from "../NotificationMessage";
import Spinner from "../../components/Spinner";

const ConfirmDetails = ({ setStep, gstDetails, panDetails, step }) => {
  const fileInputRef = React.useRef(null);
  const [stateArray, setStateArray] = React.useState([]);
  const [cityArray, setCityArray] = React.useState([]);
  const [industryTypeArray, setIndustryTypeArray] = React.useState([]);
  const [stateId, setStateId] = React.useState(0);
  const [cityId, setCityId] = React.useState(0);
  const [industryTypeId, setIndustryTypeId] = React.useState(0);
  // const [userData, setUserData] = React.useState({});
  const [image, setImage] = React.useState(null);
  const [placement, setPlacement] = React.useState("bottomEnd");
  const [base64Image, setBase64Image] = React.useState(""); // This will hold the base64 string
  const [imageFile, setImageFile] = React.useState(null);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [customerId, setCustomerId] = React.useState();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const userData = JSON.parse(Cookies.get("customerData"));
  // const { customerInfo } = useSelector((state) => state);
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
  const [imageUrl, setImageUrl] = React.useState(null);
  const [rawImageUrl, setRawImageUrl] = React.useState(null);

  console.log("step", step);

  const { onboardingStatus } = useSelector((state) => state);

  // console.log("onboardingStatus", onboardingStatus);

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  // const gstData = JSON?.parse(Cookies?.get("gstData")) || null;
  // const panData = JSON?.parse(Cookies?.get("panData")) || null;

  useEffect(() => {
    if (gstDetails) {
      setValue("organisationName", gstDetails?.legalName); // Set organization name from gstDetails
      setValue("gstNumber", gstDetails?.gstin); // Set GST number
      setValue("organisationAddress", gstDetails?.principalPlaceOfBusiness);
    }
    if (panDetails) {
      setValue("panNumber", panDetails?.idNumber); // Set PAN number
    }
  }, [gstDetails, panDetails, setValue]);

  const watchAllFields = watch();

  const addMessage = (
    <Message showIcon type={"success"} closable>
      <strong>{"Region added successfully"} </strong>
    </Message>
  );
  const deleteMessage = (
    <Message showIcon type={"error"} closable>
      <strong>{"Region delete successfully"} </strong>
    </Message>
  );
  const updateMessage = (
    <Message showIcon type={"success"} closable>
      <strong>{"Region update successfully"} </strong>
    </Message>
  );

  const industryTypeArray1 = [
    { industryTypeName: "IT", industryType: "it" },
    { industryTypeName: "Finance", industryType: "finance" },
    { industryTypeName: "Automobile", industryType: "automobile" },
  ];

  const headers = {
    ClientServiceType: 1,
    RequestMode: 1,
    token: Cookies.get("TopHrToken"),
    // "Authorization" : `Bearer ${Cookies.get("myToken")}`,
  };

  const { isLoading: ipLoadingState } = useQuery(
    ["getStateByCountryId", industryTypeId],
    () => api.get("master-service/v1/states/1"),
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          setStateArray(res.data);
        }
      },
    }
  );

  useEffect(() => {
    const hasChanges = Object.values(watchAllFields).some(
      (value) => value !== undefined && value !== ""
    );
    setIsButtonDisabled(!hasChanges);
  }, [watchAllFields]);

  const { isLoading: ipLoadingCity } = useQuery(
    [stateId],
    () => api.get(`master-service/v1/cities/${stateId}`),
    {
      onSuccess: (res) => {
        if (res.status) {
          setCityArray(res.data);
        }
      },
    }
  );

  const { data: IndustryTypes, isLoading: ipLoadingIndustryTypes } = useQuery(
    ["getIndustryTypes"],
    () => api.get(`master-service/v1/industry-types`),
    {
      onSuccess: (res) => {
        if (res.status) {
          setIndustryTypeArray(res?.data);
        }
      },
    }
  );

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];
  //   console.log("FILE", file);
  //   if (file && file.type.startsWith("image/")) {
  //     if (file.size <= 5000000) {
  //       // 5MB max size
  //       const reader = new FileReader();
  //       console.log(reader, "reader");
  //       reader.onloadend = () => {
  //         setImage(reader.result);
  //       };
  //       reader.readAsDataURL(file);
  //     } else {
  //       alert("File size exceeds 5MB");
  //     }
  //   } else {
  //     alert("Please select a valid image file");
  //   }
  // };

  // console.log("image", image);

  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];

  //   if (file && file.type.startsWith("image/")) {
  //     if(file.size <= 5000000){
  //       // 5MB max size
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         const base64String = reader.result.split(",")[1]; // Get the base64 string without the prefix
  //         setImage(reader.result); // Set the image to display
  //         setBase64Image(base64String); // Store the base64 string to send in the payload
  //       };
  //       reader.readAsDataURL(file); // This will trigger the onloadend function
  //     }else{
  //       alert("File size exceeds 5MB");
  //     }
  //     }else{
  //       alert("Please select a valid image file");

  //     }

  // };

  // console.log(base64Image,"base64")

  const convertBase64ToBlob = useCallback((base64Data) => {
    const base64WithoutPrefix = base64Data.replace(
      /^data:image\/\w+;base64,/,
      ""
    );
    const byteCharacters = atob(base64WithoutPrefix);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: "image/jpeg" });
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      if (file.size <= 5000000) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const blob = convertBase64ToBlob(reader.result);
          setImageUrl(URL.createObjectURL(blob));
          setImageFile(blob);
        };
        reader.readAsDataURL(file);
      } else {
        alert("File size exceeds 5MB");
      }
    } else {
      alert("Please select a valid image file");
    }
  };

  const confirmDetails = (data) => {
    const formData = new FormData();

    if (imageFile) {
      formData.append("file", imageFile, "image.jpg");
    } else if (imageUrl && imageUrl.startsWith("blob:")) {
      fetch(imageUrl)
        .then((res) => res.blob())
        .then((blob) => {
          formData.append("file", blob, "image.jpg");
        })
        .catch((err) => {
          console.error("Error fetching the blob for the image URL", err);
        });
    }

    const addCustomerDto = {
      name: data.organisationName,
      industryTypeId: data.industryTypes,
      address: data.organisationAddress,
      pinCode: data.pincode,
      cityId: data.city,
      officialEmailAddress: data.organisationOfficeEmailAddress,
      contactDetails: data.organisationContactNumber,
      authorizedSignatory: data.authorizedSignature,
      gstNumber: data.gstNumber,
      panNumber: data.panNumber,
      esicCode: data.esicCode,
      epfoCode: data.epfoCode,
      helpdeskEmail: data.helpDeskEmail,
      organisationCode: data.organisationCode,
      displayName: "image",
    };
    formData.append("addCustomerDto", JSON.stringify(addCustomerDto)); // 'addCustomerDto' is the key
    {
      onboardingStatus?.customer
        ? UpdateCustomer(formData)
        : AddCustomer(formData);
    }
  };

  const handleSubmit3 = () => {
    AddCustomer({
      name: "Techewewr",
      industryTypeId: 1,
      address: "123 bangalore",
      pinCode: "123456",
      cityId: 530,
      officialEmailAddress: "contact@abccorp.com",
      contactDetails: "1234567890",
      authorizedSignatory: "Abhishek",
      gstNumber: "GST123456789",
      panNumber: "ABCDE1234F",
      esicCode: "ESIC1234567890123",
      epfoCode: "EPFO12345678901234567",
      helpdeskEmail: "helpdeskEmail@gmail.com",
      displayImage: image,
      displayName: "image",
    });
  };

  const { mutate: AddCustomer, isLoading: addCustomerLoading } = useMutation(
    ["AddCustomer"],
    async (body) => await api.post(`master-service/v1/customer`, body),
    {
      onSuccess: (res) => {
       
        if (res?.data?.statusCode == 200) {
          console.log("res", res.data?.customerId);
          let data = JSON.stringify(res?.data);
          Cookies.set("customerData", data);
          // setCustomerId(res.data?.customerId);
           dispatch(handleOnboardingStatus({ customer: true }));

       
        } 
        // const customerId = res?.data?.customerId;
        // Cookies.set("customerData", customerId);

        setStep((prev) => prev + 1);
        // CustomerSetUpLogs({ level: "customer" });
      },
      onError: (err) => {
        // console.log("error", err.response);
        const notificationKey = toaster.push(
          <NotificationMessage
            type="error"
            title="Error!"
            description={err?.response?.data?.message}
            onClose={() => toaster.remove(notificationKey)} // Close the toaster when the close icon is clicked
          />,
          { placement: "bottomEnd", duration: 5000 }
        );
      },
    }
  );

  const { mutate: UpdateCustomer, isLoading: UpdateCustomerLoading } =
    useMutation(
      ["update-customer"],
      async (body) =>
        await api.put(
          `master-service/v1/customers/${userData?.customerId}`,
          body
        ),
      {
        onSuccess: (res) => {
          let data = JSON.stringify(res?.data);
          // Cookies.set("customerData", data);
          if (res?.data?.statusCode === 200) {
            try {
              const notificationKey = toaster.push(
                <NotificationMessage
                  type="success"
                  title="Success!"
                  description={res?.data?.message}
                  onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
                />,
                { placement: "bottomEnd", duration: 20000 }
              );
            } catch (error) {
              console.log(error);
            }
          } else {
            try {
              const notificationKey = toaster.push(
                <NotificationMessage
                  type="error"
                  title="Error!"
                  description={res?.data?.message || "Something went wrong."}
                  onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
                />,
                { placement: "bottomEnd", duration: 5000 }
              );
            } catch (error) {
              console.log(error);
            }
          }
          // const customerId = res?.data?.customerId;
          // Cookies.set("customerData", customerId);

          setStep((prev) => prev + 1);
          // CustomerSetUpLogs({ level: "customer" });
        },
        onError: (err) => {
          // console.log("error", err.response);
          const notificationKey = toaster.push(
            <NotificationMessage
              type="error"
              title="Error!"
              description={err?.response?.data?.message}
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when the close icon is clicked
            />,
            { placement: "bottomEnd", duration: 5000 }
          );
        },
      }
    );

    
 // Shift this part to next page because it is not working here 
 
  // useEffect(() => {
    
  //   if (userData?.customerId) {
  //     console.log("customerId", userData?.customerId);
  //     callSetupLogsApi({ level: "customer" });
  //   }
  // },[userData?.customerId]);
  

  // const {
  //   mutate: callSetupLogsApi,
  //   isLoading: callSetupLogsApiLoading,
  //   refetch: refetchCallSetupLogsApi,
  // } = useMutation(
  //   ["setup-log"],
  //   async (body) =>
  //     await api.put(
  //       `master-service/v1/customers/${userData?.customerId}/setup-log`,
  //       body
  //     ),
  //   {
  //     onSuccess: (res) => {
  //       // toaster.push(updateMessage, { placement, duration: 5000 });
  //       // setStep((prev) => prev + 1);
  //     },
  //     onError: (err) => {
  //       console.log("error", err.response);
  //     },
  //   }
  // );

  useEffect(() => {
    setCityArray([]);
  }, [stateId]);

  const {
    data: CustomerInto,
    isLoading: isLoadingCustomer,
    refetch: refetchCustomer,
  } = useQuery(
    ["get-customers"],
    () => api.get(`master-service/v1/customers/${userData?.customerId}`),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      enabled: onboardingStatus.customer,
      select: (res) => res.data,
    }
  );

  const convertBase64ToBlob2 = useCallback((base64Data) => {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: "image/jpeg" });
  }, []);

  useEffect(() => {
    if (CustomerInto?.displayImage) {
      try {
        const blob = convertBase64ToBlob2(CustomerInto.displayImage);
        const url = URL.createObjectURL(blob);
        setImageUrl(url);
        setImageFile(blob);
      } catch (error) {
        console.error("Error converting image:", error);
        setImageFile(null);
        setImageUrl(null);
      }
    }
  }, [CustomerInto, convertBase64ToBlob2]);

  useEffect(() => {
    // console.log("CustomerInto", CustomerInto);
    if (CustomerInto && onboardingStatus.customer) {
      setValue("organisationName", CustomerInto?.name);
      setValue("industryTypes", CustomerInto?.industryType?.id);
      setValue("organisationAddress", CustomerInto?.address);
      setValue("pincode", CustomerInto?.pincode);
      setValue("city", CustomerInto?.city?.id);
      setValue(
        "organisationOfficeEmailAddress",
        CustomerInto?.officialEmailAddress
      );
      setValue("organisationContactNumber", CustomerInto?.contactDetails);
      setValue("authorizedSignature", CustomerInto?.authorizedSignatory);
      setValue("gstNumber", CustomerInto?.gstNumber);
      setValue("panNumber", CustomerInto?.panNumber);
      setValue("esicCode", CustomerInto?.esicCode);
      setValue("epfoCode", CustomerInto?.epfoCode);
      setValue("helpdeskEmail", CustomerInto?.helpdeskEmail);
      setValue("state", CustomerInto?.state?.id);
      setIndustryTypeId(CustomerInto?.industryType?.id);
      setStateId(CustomerInto?.state?.id);
      setCityId(CustomerInto?.city?.id);
    }
  }, [CustomerInto, setValue]);

  return (
    <>
      {UpdateCustomerLoading ||
      addCustomerLoading ||
      (onboardingStatus.customer && isLoadingCustomer) ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center", // Centers vertically
            height: "100vh", // Takes full viewport height
          }}
        >
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="login-bg-new-img">
            <div className="login-page-modals new-modal2 new-width">
              <div className="login-inner-modal modal-3">
                <div className="back-btn">
                  <a
                    href="#"
                    className="color-light"
                    onClick={() =>
                      onboardingStatus.customer
                        ? setStep(7)
                        : setStep((prev) => prev - 2)
                    }
                  >
                    <IoIosArrowBack />
                    Back
                  </a>
                </div>
                <h3 className="mt-3">Confirm details!</h3>
                <p className="my-3">
                  Please confirm the details displayed below.
                </p>

                <form onSubmit={handleSubmit(confirmDetails)}>
                  <div>
                    <div className="container-fluid p-0 ">
                      <div className="row modal-body-2">
                        <div className="col-md-4">
                          <div className="logo-upload-section mt-2">
                            <div className="logo-upload">
                              {/* <img
                  src="/images/TopHRLogo.svg"
                  style={{ maxWidth: "70%" }}
                /> */}
                              {imageUrl ? (
                                <div style={{ marginTop: "10px" }}>
                                  <img
                                    className="logo-upload-img"
                                    src={imageUrl}
                                    alt="Uploaded Logo"
                                    // style={{ width: "200px", height: "auto" }}
                                    style={{
                                      // position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "fill",
                                    }}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#f0f0f0",
                                  }}
                                >
                                  LOGO
                                </div>
                              )}
                            </div>
                            <div className="text-center">
                              <Button
                                onClick={handleButtonClick}
                                className="btn-large mt-3"
                                appearance="primary"
                                type="button"
                              >
                                Upload Logo
                              </Button>

                              <div>
                                <input
                                  type="file"
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  ref={fileInputRef}
                                  onChange={handleImageChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-1">
                            <label>Industry Type*</label>
                            <br />
                            <Controller
                              name="industryTypes"
                              control={control}
                              rules={{ required: "industry Types is required" }}
                              render={({ field }) => (
                                <SelectPicker
                                  {...field}
                                  data={industryTypeArray?.map((item) => {
                                    return {
                                      label: item?.name, // The name will be shown as the dropdown label
                                      value: item?.id,
                                    };
                                  })}
                                  value={industryTypeId}
                                  style={{ width: "153px" }}
                                  onChange={(value) => {
                                    field.onChange(value);
                                    setIndustryTypeId(value);
                                  }}
                                />
                              )}
                            />
                          </div>
                          <span className="d-block requird-color">
                            {errors.industryTypes &&
                              errors.industryTypes.message}
                          </span>
                          <div className="mb-1">
                            <label>Organisation Name*</label>
                            <br />
                            {/* <Input placeholder="Enter department name" /> */}
                            <input
                              placeholder="Enter organisation name"
                              type="text"
                              className="form-control"
                              {...register("organisationName", {
                                required: "Organisation Name is required",
                              })}
                              // readOnly = {gstData===null?false:true}
                            />
                          </div>
                          <span className=" d-block requird-color">
                            {errors.organisationName &&
                              errors.organisationName.message}
                          </span>
                          <div className="mb-1">
                            <label>Organisation Code</label>
                            <br />
                            <input
                              placeholder="Enter organisation code"
                              className="form-control"
                              type="text"
                              {...register("organisationCode")}
                              // {...register("companyCode", {
                              //   required: "Company Code is Required",
                              // })}
                            />
                          </div>
                          <span className="d-block requird-color">
                            {errors.organisationCode &&
                              errors.organisationCode.message}
                          </span>
                          <div className="mb-1">
                            <label>Organisation Address*</label>
                            <br />
                            <input
                              placeholder="Enter organisation address"
                              type="text"
                              className="form-control"
                              {...register("organisationAddress", {
                                required: "Organisation Address is required",
                              })}
                              readOnly={gstDetails == null || gstDetails == undefined ? false : true}
                            />
                          </div>
                          <span className="d-block requird-color">
                            {errors.organisationAddress &&
                              errors.organisationAddress.message}
                          </span>
                          <div className="mb-1">
                            <label>Pincode*</label>
                            <br />
                            <input
                              placeholder="Enter pincode"
                              type="number"
                              className="form-control"
                              {...register("pincode", {
                                required: "Pincode is required",
                                pattern: {
                                  value: /^[0-9]{6}$/, // Regex for validating 6-digit numeric pincode
                                  message: "Pincode must be a 6-digit number",
                                },
                                minLength: {
                                  value: 6,
                                  message: "Pincode must be exactly 6 digits",
                                },
                                maxLength: {
                                  value: 6,
                                  message: "Pincode must be exactly 6 digits",
                                },
                              })}
                            />
                          </div>
                          <span className="d-block requird-color">
                            {errors.pincode && errors.pincode.message}
                          </span>
                          <div>
                            <div className="row">
                              <div className="col-md-6 mb-1">
                                <label>State*</label>
                                <br />
                                <Controller
                                  name="state"
                                  control={control}
                                  rules={{ required: "State is required" }}
                                  render={({ field }) => (
                                    <SelectPicker
                                      {...field}
                                      data={stateArray?.map((item) => {
                                        return {
                                          label: item.name,
                                          value: item.id,
                                        };
                                      })}
                                      value={stateId}
                                      style={{ width: "153px" }}
                                      onChange={(value) => {
                                        field.onChange(value);
                                        setStateId(value);
                                      }}
                                    />
                                  )}
                                />
                                <span className="d-block requird-color">
                                  {errors.state && errors.state.message}
                                </span>
                              </div>
                              <div className="col-md-6 mb-1">
                                <label>City*</label>
                                <br />
                                <Controller
                                  name="city"
                                  control={control}
                                  rules={{ required: "City is required" }}
                                  render={({ field }) => (
                                    <SelectPicker
                                      {...field}
                                      data={cityArray?.map((item) => {
                                        return {
                                          label: item.name,
                                          value: item.id,
                                        };
                                      })}
                                      value={cityId}
                                      style={{ width: "153px" }}
                                      onChange={(value) => {
                                        field.onChange(value);
                                        setCityId(value);
                                      }}
                                    />
                                  )}
                                />
                                <span className="d-block requird-color">
                                  {errors.city && errors.city.message}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="mb-1">
                            <label>Organisation Official Email Address*</label>
                            <br />
                            <input
                              placeholder="Enter organisation official email address"
                              type="text"
                              className="form-control"
                              {...register("organisationOfficeEmailAddress", {
                                required: "Company email address required",
                              })}
                            />
                          </div>
                          <span className="d-block requird-color">
                            {errors.organisationOfficeEmailAddress &&
                              errors.organisationOfficeEmailAddress.message}
                          </span>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-1">
                            <label>Organisation Contact Number*</label>

                            <br />
                            <Controller
                              name="organisationContactNumber"
                              control={control}
                              rules={{
                                minLength: {
                                  value: 10,
                                  message:
                                    "Organisation Contact Number must be exactly 10 digits",
                                },
                                maxLength: {
                                  value: 10,
                                  message:
                                    "Organisation Contact Number must be exactly 10 digits",
                                },
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message:
                                    "Organisation Contact Number must be numeric",
                                },
                              }}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  placeholder="Enter organisation contact number"
                                  type="number"
                                  className="form-control"
                                  {...register("organisationContactNumber", {
                                    required:
                                      "Organisation Contact Number is required",
                                  })}
                                />
                              )}
                            />
                          </div>
                          <span className="d-block requird-color">
                            {errors.organisationContactNumber &&
                              errors.organisationContactNumber.message}
                          </span>
                          <div className="mb-1">
                            <label>Authorized signatory*</label>
                            <br />
                            <input
                              placeholder="Enter authorized Signatory name"
                              type="text"
                              className="form-control"
                              {...register("authorizedSignature", {
                                required: "Authorized signature required",
                              })}
                            />
                          </div>
                          <span className="d-block requird-color">
                            {errors.authorizedSignature &&
                              errors.authorizedSignature.message}
                          </span>
                          <div className="mb-1">
                            <label>GST Number</label>
                            <br />
                            <input
                              placeholder="Enter GST number of your organisation"
                              className="form-control"
                              {...register("gstNumber")}
                              // {...register("gstNumber", {
                              //   required: "Gst Number is Required",
                              // })}
                              readOnly={gstDetails == null || gstDetails == undefined ? false : true}
                            />
                          </div>
                          <span className="d-block requird-color">
                            {errors.gstNumber && errors.gstNumber.message}
                          </span>
                          <div className="mb-1">
                            <label>PAN Number</label>
                            <br />
                            <input
                              placeholder="Enter PAN Number"
                              className="form-control"
                              {...register("panNumber")}
                              // {...register("panNumber", {
                              //   required: "PAN number is required",
                              // })}
                              readOnly={panDetails == null || panDetails == undefined ? false : true}
                            />
                          </div>
                          <span className="d-block requird-color">
                            {errors.panNumber && errors.panNumber.message}
                          </span>
                          <div className="mb-1">
                            <label>ESIC Code</label>
                            <br />
                            <input
                              placeholder="Enter ESIC Code"
                              className="form-control"
                              {...register("esicCode")}
                              // {...register("esicCode", {
                              //   required: "ESIC Code required",
                              // })}
                            />
                          </div>
                          <span className="d-block requird-color">
                            {errors.esicCode && errors.esicCode.message}
                          </span>
                          <div className="mb-1">
                            <label>EPFO Code</label>
                            <br />
                            <input
                              placeholder="Enter EPFO Code"
                              className="form-control"
                              {...register("epfoCode")}
                              // {...register("epfoCode", {
                              //   required: "EPFO Code is required",
                              // })}
                            />
                          </div>
                          <span className="d-block requird-color">
                            {errors.epfoCode && errors.epfoCode.message}
                          </span>
                          <div className="mb-1">
                            <label>Helpdesk Email Address</label>
                            <br />
                            <input
                              placeholder="Enter Helpdesk Email Address"
                              className="form-control"
                              {...register("helpDeskEmail")}
                              // {...register("helpDeskEmail", {
                              //   required: "Helpdesk Email Address required",
                              // })}
                            />
                          </div>
                          <span className="d-block requird-color">
                            {errors.helpDeskEmail &&
                              errors.helpDeskEmail.message}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="text-center">
                      <Button
                        className="btn-large mt-2 w-50"
                        appearance="primary"
                        // onClick={() => setStep(4)}
                        type="submit"
                        disabled={isButtonDisabled} // Disable the button based on the state
                      >
                        {onboardingStatus?.customer ? (
                          <div>Update</div>
                        ) : (
                          <div>Confirm</div>
                        )}
                      </Button>
                    </div>
                  </div>

                  {/* <div>
                <div className="text-center">
                  <Button
                    className="btn-large mt-3 w-75"
                    appearance="primary"
                    onClick={() => {setStep((prev) => prev + 1);
                      dispatch(handleOnboardingStatus({ customer: true }));
                    }}
                    // type="submit"
                  >
                    go to next page without submit data
                  </Button>
                </div>
              </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmDetails;
