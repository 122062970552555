import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Button, Checkbox, Input, toaster } from "rsuite";
import { TiDelete } from "react-icons/ti";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import api from "../../lib/api";
import NotificationMessage from "../NotificationMessage";
import Cookies from "js-cookie";

const GSTNumberOfOrganisation = ({ setStep,setGstDetails ,step}) => {
  const [gst, setGst] = useState("");
  const [consentGiven, setConsentGiven] = useState(false);

  console.log("step", step);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const gstInput = watch("gst");

  const { mutate: verifyGstNumber, isLoading } = useMutation(
    async (gst) => await api.post(`master-service/v1/gstin-verification?id=${gst}`),
    {
      onSuccess: (res) => {
        console.log(res,"getResponse");

        if (res?.data?.statusCode === 200) {
          Cookies.set("gstData",JSON.stringify(res?.data))

          setGstDetails(res?.data); // Save GST details in the parent component
          const notificationKey = toaster.push(
            <NotificationMessage
              type="success"
              title="Success!"
              description={res?.data?.message}
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
            />,
            { placement: "bottomEnd", duration: 20000 }
          );

          setStep(3); // Move to the next step on success
        } else {
          const notificationKey = toaster.push(
            <NotificationMessage
              type="error"
              title="Error!"
              description={res?.data?.message || "Something went wrong."}
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
            />,
            { placement: "bottomEnd", duration: 5000 }
          );
        }
      },
      onError: (err) => {
        console.log("Error", err?.response);
        const notificationKey = toaster.push(
          <NotificationMessage
            type="error"
            title="Error!"
            description={err?.response?.data?.message || "Failed to verify GST number."}
            onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
          />,
          { placement: "bottomEnd", duration: 5000 }
        );
      },
    }
  );


  const handleGstNumber = (data) => {
    console.log("postgst",data)
    const {gst} = data;
    verifyGstNumber(gst)
    setStep((prev) => prev + 1);
  };

  const handleSkip = () => {
    // Define what happens when "Skip" is clicked
    Cookies.set("gstData",null)
    setStep((prev) => prev + 2); 
    // setStep(6);
  };

  const handleConsentChange = () => {
    setConsentGiven((prev) => !prev);
  };

  return (
    <div>
      <div className="login-bg-new-img">
        <div className="login-page-modals">
          <div className="login-inner-modal modal-2 new-width">
            <div className="back-btn">
              <a
                href="#"
                className="color-light"
                onClick={() => setStep((prev) => prev - 1)}
              >
                <IoIosArrowBack />
                Back
              </a>
            </div>
            <h3 className="mt-3">
              {" "}
              GST Number Of Your
              <br /> Organisation{" "}
            </h3>
            <p className="my-3">Please Enter Your GST Number to Continue.</p>
            <form onSubmit={handleSubmit(handleGstNumber)}>
              <div className="n-width">
                <div className="align-items-end d-flex">
                  <div className="w-100">
                    <label>GST number</label>
                    {/* <Input
                  style={{ width: 300 }}
                  placeholder="Enter GST number of your organisation"
                /> */}
                    <input
                      type="text"
                      placeholder="Enter GST number of your organisation"
                      className="form-control"
                      {...register("gst", {
                        required: "Gst Number is Required",
                        pattern: {
                          value:
                            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                          message: "Enter a valid GST number",
                        },
                      })}
                    />
                  </div>
                </div>
                <span className="pt-2 d-block requird-color">
                  {errors.gst && errors.gst.message}
                </span>
              </div>
              <div>
                <div className="d-flex mt-2">
                  <div className="d-flex align-items-center">
                    
                    <div className="d-flex">
                      <Checkbox  
                        // onChange={(value)=>console.log(value)}
                        // onClick={(value)=>console.log(value)}
                        onChange={handleConsentChange} 
                      />
                      <p style={{ fontSize: "12px", fontWeight: "500" }}>
                        I Provide consent to process the shared information
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center d-flex justify-content-center mt-3 custom-button-gap">
                <Button
                  className="btn-large mt-3 w-50"
                  appearance="default"
                  onClick={handleSkip}
                  // style={{ borderColor: 'gray' }} // Apply gray border
                  style={{ border: "1px solid #dcdcdc" }}
                  // Add margin for spacing
                >
                  Skip
                </Button>

                <Button
                  className="btn-large mt-3 w-50"
                  appearance="primary"
                  // onClick={() => setStep(3)}
                  type="submit"
                  // disabled={!gstInput} // Disable if gstInput is empty
                  disabled={!gstInput || !consentGiven || isLoading} 


                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GSTNumberOfOrganisation;
