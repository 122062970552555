import PeoplesIcon from "@rsuite/icons/Peoples";
import { BsFileEarmarkText } from "react-icons/bs";
import { LiaUserClockSolid } from "react-icons/lia";
import { FaHandHoldingWater } from "react-icons/fa";

export const navModule = [
  {
    title: "Attendance",
    icon: <LiaUserClockSolid className="new-icn" />,
    menu: [
      {
        title: "Bulk Upload",
        link: "/BulkUpload",
      },
      {
        title: "Configurations",
        link: "/configurations",
      },

      {
        title: "Master Roll",
        link: "/master-roll",
      },
      {
        title: "Monthly Report",
        link: "/MonthlyReport",
      },
      {
        title: "Holiday Settings",
        link: "/holiday-settings",
      },
    ],
  },
  {
    title: "Leave Management",
    icon: <BsFileEarmarkText className="new-icn" />,
    menu: [
      {
        title: "Bulk Upload",
        link: "/bulkUploadLeave", 
      },
      {
        title: "Leave Application",
        link: "/leaveApplication",
      },
      {
        title: "Leave Types",
        link: "/leaveTypes",
      },
      {
        title: "Apply Leave",
        link: "/applyLeave",
      },
      {
        title: "Leave Balance",
        link: "/leavebalance",
      },
      {
        title: "Application Details",
        link: "/applicationDetails",
      },
    ],
  },
  {
    title: "Payroll Management",
    icon: <FaHandHoldingWater className="new-icn" />,
    menu: [
      {
        title: "Opening Balance",
        link: "/Opening-Balance",
      },
      {
        title: "Account Master",
        link: "/Account-Master",
      },
    ],
  },
];
