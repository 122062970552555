import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    customerInfo: "",
    customerId: null,
    test: "",
    onboardingStatus: JSON.parse(localStorage.getItem("onboardingStatus")) || {
    customer: false,
    region: false,
    department: false,
    structureMapping: false,
    designation: false,
  },
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    customerInfo: (state, action) => {
      state.customerInfo = action.payload;
    },
    customerId: (state, action) => {
        state.customerId = action.payload;
        },
    testData: (state, action) => {
      state.test = action.payload;
    },
    handleOnboardingStatus: (state, action) => {
      if (action.payload !== undefined) {
        state.onboardingStatus = {
          ...state.onboardingStatus,
          ...action.payload,
        };
        localStorage.setItem("onboardingStatus", JSON.stringify(state.onboardingStatus));
      }
    },
  },
});

export const { customerInfo, testData,customerId,handleOnboardingStatus } = userSlice.actions;
export default userSlice.reducer;
