import React from "react";
import { IconButton, Message,toaster  } from "rsuite";
import CloseIcon from '@rsuite/icons/Close'; // Import the close icon
import "rsuite/dist/rsuite.min.css"; // Make sure you import RSuite styles



const NotificationMessage = ({ type, title, description, duration = 5000, placement = "topCenter",onClose }) => {
  const borderColor = type === "success" ? "#4CAF50" : "#F44336"; // Green for success, red for error
  const iconContent = type === "success" ? "✓" : "✕"; // Icon content changes based on type
  return (
   <div style={{width:"300px"}}>
     <Message showIcon={true} type={type} closable onClose={onClose}>
        
        <strong>{title}</strong>
        <p>{description}</p>
        
      </Message>
   </div>
  // <div style={{
  //   width: "300px",
  //   border: `2px solid ${borderColor}`,
  //   borderRadius: "8px",
  //   padding: "16px",
  //   backgroundColor: "#EEFAF3",
  //   position: "relative"
  // }}>
  //   {/* Header row with icon, icon content, and close button */}
  //   <div style={{
  //     display: "flex",
  //     justifyContent: "space-between",
  //     alignItems: "center"
  //   }}>
  //     {/* Icon and content beside it */}
  //     <div style={{ display: "flex", alignItems: "center" }}>
  //       {/* Success or error icon */}
  //       <div style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         flexDirection: "column",  // Stacks icon and title vertically

  //         width: "30px",
  //         height: "30px",
  //         // borderRadius: "50%",
  //         borderRadius: "8px",  // Light border radius

  //         backgroundColor: borderColor,
  //         marginRight: "10px"
  //       }}>
  //         <span style={{ color: "#fff", fontSize: "18px" }}>{iconContent}</span>
  //       </div>
  //       {/* Success or Error title beside the icon */}
  //       <strong style={{ fontSize: "16px", color: borderColor }}>
  //         {title}
  //       </strong>
  //     </div>

  //     {/* Close button */}
  //     <IconButton
  //       icon={<CloseIcon />}
  //       onClick={onClose}
  //       style={{
  //         background: "none",
  //         border: "none",
  //         padding: "5px"
  //       }}
  //     />
  //   </div>

  //   {/* Response message */}
  //   {/* <div style={{ marginTop: "8px",  color: "#555" }}>
  //     <p>{description}</p>
  //   </div> */}
  //    <div style={{ marginTop: "4px", color: "#555" }}> {/* Reduced margin for better alignment */}
  //       <p style={{ marginLeft: "40px" }}>{description}</p> {/* Removed default margin for paragraphs */}
  //     </div>
  // </div>
  );
};

export default NotificationMessage;
