import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { IoIosArrowBack } from 'react-icons/io'
import { Button, Checkbox, toaster } from 'rsuite'
import NotificationMessage from '../NotificationMessage';
import api from '../../lib/api';
import { useMutation } from '@tanstack/react-query';
import Cookies from 'js-cookie';

const PANNumberOfOrganisation = ({setStep,setPanDetails,step}) => {
  const [consentGiven, setConsentGiven] = useState(false);

  console.log("step", step);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { mutate: verifyPANNumber, isLoading } = useMutation(
    async (pan) => await api.post(`master-service/v1/verify-document/pan?id=${pan}&documentType=PAN`),
    {
      onSuccess: (res) => {

        if (res?.data?.statusCode === 200) {
          Cookies.set("panData",JSON.stringify(res?.data))
          setPanDetails(res?.data)
          
          const notificationKey = toaster.push(
            <NotificationMessage
              type="success"
              title="Success!"
              description={res?.data?.message}
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
            />,
            { placement: "bottomEnd", duration: 20000 }
          );

          setStep((prev) => prev + 1); // Move to the next step on success
        } else {
          const notificationKey = toaster.push(
            <NotificationMessage
              type="error"
              title="Error!"
              description={res?.data?.message || "Something went wrong."}
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
            />,
            { placement: "bottomEnd", duration: 5000 }
          );
        }
      },
      onError: (err) => {
        console.log("Error", err?.response);
        const notificationKey = toaster.push(
          <NotificationMessage
            type="error"
            title="Error!"
            description={err?.response?.data?.message || "Failed to verify GST number."}
            onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
          />,
          { placement: "bottomEnd", duration: 5000 }
        );
      },
    }
  );

  const handlePanNumber = (data) => {
    verifyPANNumber(data.pan);
    
    // setStep((prev) => prev + 1);
  };

  const handleSkip = () => {
    // Define what happens when "Skip" is clicked
    // setStep((prev) => prev + 1); // Example: Just move to the next step
    Cookies.set("panData",null)

    setStep(6)
  };

  const handleConsentChange = () => {
    setConsentGiven((prev) => !prev);
  };

  const panInput = watch("pan");

  return (
    <div>
      <div className='login-bg-new-img'>
        <div className='login-page-modals'>
            <div className="login-inner-modal modal-2 new-width">
                <div className="back-btn">
                <a
                href="#"
                className="color-light"
                onClick={() => setStep((prev) => prev - 2)}
              >
                <IoIosArrowBack />
                Back
              </a>

                </div>
                <h3 className="mt-3">
              {" "}
              PAN Number Of Your
              <br /> Organisation{" "}
            </h3>
            <p className="my-3">Please Enter Your PAN Number to continue.</p>
            <form onSubmit={handleSubmit(handlePanNumber)}>
              <div className="n-width">
                <div className="align-items-end d-flex">
                  <div className="w-100">
                    <label>PAN number</label>
                    {/* <Input
                  style={{ width: 300 }}
                  placeholder="Enter GST number of your organisation"
                /> */}
                    <input
                      type="text"
                      placeholder="Enter PAN number of your organisation"
                      className="form-control"
                      {...register("pan", {
                        required: "PAN Number is Required",
                        pattern: {
                          value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                          message: "Invalid PAN Number format",
                        },
                       
                      })}
                    />
                  </div>
                </div>
                <span className="pt-2 d-block requird-color">
                  {errors.pan && errors.pan.message}
                </span>
              </div>
              <div className="d-flex">
                  <div className="d-flex align-items-center">
                    
                    <div className="d-flex">
                      <Checkbox 
                      onChange={handleConsentChange} 
                      />
                      <p style={{ fontSize: "12px", fontWeight: "500" }}>
                        I Provide consent to process the shared information.
                      </p>
                    </div>
                  </div>
                </div>
              <div className="text-center d-flex justify-content-center mt-3 custom-button-gap">
                <Button
                  className="btn-large mt-3 w-50"
                  appearance="default"
                  onClick={handleSkip}
                  // style={{ borderColor: 'gray' }} // Apply gray border
                  style={{ border: "1px solid #dcdcdc" }}
                  // Add margin for spacing
                >
                  Skip
                </Button>

                <Button
                  className="btn-large mt-3 w-50"
                  appearance="primary"
                  // onClick={() => setStep(3)}
                  type="submit"
                  // disabled={!panInput} // Disable if gstInput is empty
                  disabled={!panInput || !consentGiven || isLoading} 

                >
                  Submit
                </Button>
              </div>
            </form>
            </div>
        </div>
      </div>
    </div>
  )
}

export default PANNumberOfOrganisation
