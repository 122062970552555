import React from "react";

const Spinner = () => {
  return (
    <div className="spinner-container">
      <div className="spinner">
        {[...Array(12)].map((_, index) => (
          <div key={index} className={`dot dot${index + 1}`}></div>
        ))}
      </div>
      <p>Loading, please wait...</p>
      <style jsx>{`
        .spinner-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 80px;
          width: 100%;
        }
        .spinner {
          position: relative;
          width: 60px;
          height: 60px;
          margin-bottom: 10px;
        }
        .dot {
          position: absolute;
          width: 8px;
          height: 8px;
          background-color: black;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          animation: clockwiseSpin 1.2s linear infinite;
        }
        ${[...Array(12)]
          .map(
            (_, index) => `
          .dot${index + 1} {
            transform: rotate(${index * 30}deg) translateY(-25px);
          }
        `
          )
          .join("")}
        @keyframes clockwiseSpin {
          0% {
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        ${[...Array(12)]
          .map(
            (_, index) => `
          .dot${index + 1} {
            animation-delay: ${-index * 0.1}s;
          }
        `
          )
          .join("")}
      `}</style>
    </div>
  );
};

export default Spinner;
