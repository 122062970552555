/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Button, Input } from "rsuite";
import { IoCheckmarkCircle } from "react-icons/io5";
import { IoSettings } from "react-icons/io5";
import { useSelector } from "react-redux";

const OrganisationStructure = ({ step, setStep }) => {
    const {onboardingStatus} = useSelector((state) => state);
  console.log("step",step);
  return (
    <div>
     <div className="login-bg-new-img">
     <div className="login-page-modals">
        <div className="login-inner-modal modal-4">
          <div className="back-btn">
            <a
              href="#"
              className="color-light"
              onClick={() => {
                setStep(7);
              }}
            >
              <IoIosArrowBack />
              Back
            </a>
          </div>
          <h3 className="mt-3">Organisation Structure </h3>
          <p className="my-3">
            Please configure your organisational structure to continue.
          </p>
          <div>
            { onboardingStatus.region == false && onboardingStatus.department == false && onboardingStatus.structureMapping == false ? (
              <div>
                <p className="data-text color-light">YET TO COMPLETE</p>
                <div className="container-fluid p-0">
                  <div className="row mt-3">
                    <div className="col-md-4" onClick={()=>setStep(9)}>
                      <div className="strip-organisation-structure zoom" >
                        <div>
                          <IoSettings
                            style={
                             false
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">Regions</p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                              false
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4" >
                      <div className="strip-organisation-structure" >
                        <div>
                          <IoSettings
                            style={
                              false
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">Departments</p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                            false
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="strip-organisation-structure" >
                        <div>
                          <IoSettings
                            style={
                              false
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">
                              Structure Mapping
                            </p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                            false
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : onboardingStatus.region == true && onboardingStatus.department == false && onboardingStatus.structureMapping == false ? (
              <div>
                <p className="data-text color-light">YET TO COMPLETE</p>
                <div className="container-fluid p-0">
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <div className="strip-organisation-structure zoom" onClick={()=>setStep(11)}>
                        <div>
                          <IoSettings
                            style={
                              false
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">Departments</p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                              false
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="strip-organisation-structure">
                        <div>
                          <IoSettings
                            style={
                             false
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">
                              Structure Mapping
                            </p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                             false
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="data-text color-light"> COMPLETED</p>
                <div className="container-fluid p-0">
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <div className="strip-organisation-structure zoom" onClick={()=>setStep(9)}>
                        <div>
                          <IoSettings
                            style={
                              onboardingStatus.region
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>

                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">Regions</p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                              onboardingStatus.region
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : onboardingStatus.region == true && onboardingStatus.department == true && onboardingStatus.structureMapping == false ? (
              <div>
                <p className="data-text color-light">YET TO COMPLETE</p>
                <div className="container-fluid p-0">
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <div className="strip-organisation-structure zoom" onClick={()=>setStep(13)}>
                        <div>
                          <IoSettings
                            style={
                              false
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">
                              Structure Mapping
                            </p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                              false
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="data-text color-light"> COMPLETED</p>
                <div className="container-fluid p-0">
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <div className="strip-organisation-structure zoom" onClick={()=>setStep(9)}>
                        <div>
                          <IoSettings
                            style={
                             onboardingStatus.region
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>

                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">Regions</p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                             onboardingStatus.region
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="strip-organisation-structure zoom" onClick={()=>setStep(11)}>
                        <div>
                          <IoSettings
                            style={
                              onboardingStatus.department
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">Departments</p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                              onboardingStatus.department
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : onboardingStatus.region == true && onboardingStatus.department == true && onboardingStatus.structureMapping == true ? (
              <div>
                <p className="data-text color-light">COMPLETED</p>
                <div className="container-fluid p-0">
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <div className="strip-organisation-structure zoom" onClick={()=>setStep(9)}>
                        <div>
                          <IoSettings
                            style={
                              onboardingStatus.region
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>

                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">Regions</p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                             onboardingStatus.region
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="strip-organisation-structure zoom" onClick={()=>setStep(11)}>
                        <div>
                          <IoSettings
                            style={
                              onboardingStatus.department
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">Departments</p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                              onboardingStatus.department
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="strip-organisation-structure zoom" onClick={()=>setStep(13)}>
                        <div>
                          <IoSettings
                            style={
                             onboardingStatus.structureMapping
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">
                              Structure Mapping
                            </p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                              onboardingStatus.structureMapping
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

          
            <div className="text-center">
              <Button
                className="btn-large mt-3 w-50"
                appearance="primary"
                onClick={() => {
                  setStep((prev) => prev + 1);
                }}
                disabled={ onboardingStatus.region && onboardingStatus.department && onboardingStatus.structureMapping ? false : true}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
     </div>
    </div>
  );
};

export default OrganisationStructure;
